<template>
  <div class="container">
    <div class="inner-container" :class="page">
      <img class="emojise-logo" src="../assets/images/emojise-icon.png" alt="Emojise Icon">
      <Login v-if="page == 'login'" />
      <Signup v-else-if="page == 'signup'" />
      <p v-if="page == 'login'">No Account? <span class="link" @click="page = 'signup'">Create Account</span></p>
      <p v-if="page == 'signup'">Already Registered? <span class="link" @click="page = 'login'">Log In</span></p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import Login from '../components/Auth/Login.vue'
import Signup from '../components/Auth/Signup.vue'

export default {
  components: { Login, Signup },
  setup() {
    const route = useRoute()
    const page = (route.query.mode === 'create') ? ref('signup') : ref('login')

    return { page }
  }
}
</script>

<style>
  .inner-container.login, .inner-container.signup {
    text-align: center;
  }
  .inner-container.login .link, .inner-container.signup .link {
    color: var(--link);
    cursor: pointer;
  }
</style>