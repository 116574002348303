<template>
  <h2 class="top-header">Nice to meet you ✌️</h2>
  <span>Sign up for an account</span>
  <form @submit.prevent="handleSignup">
    <input type="text" placeholder="First Name" v-model="data.fName" required style="margin-top: 0">
    <input type="text" placeholder="Last Name" v-model="data.lName" required>
    <input type="email" placeholder="Email Address" v-model="data.email" required>
    <input :type="(isPassHidden) ? 'password' : 'text'" placeholder="Password" v-model="data.password" required>
    <span class="toggle-password" @click="isPassHidden = !isPassHidden" title="Show Password" tabindex="0">
      <i v-if="isPassHidden" class="fas fa-eye"></i>
      <i v-else class="fas fa-eye-slash"></i>
    </span>
    <div v-if="error" class="error">{{ error }}</div>
    <button v-if="!isLoading">Create Account</button>
    <button v-else disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
  </form>
</template>

<script>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import useAuth from '@/composables/useAuth'

export default {
  setup() {
    const router = useRouter()
    const { signup, error, isLoading } = useAuth()
    const isPassHidden = ref(true)

    const data = reactive({
      fName: '',
      lName: '',
      email: '',
      password: ''
    })

    const handleSignup = async () => {
      await signup(data)
      
      if (!error.value) {
        router.push({ name: 'Dashboard', params: { isNew: true } })
      }
    }

    return { data, handleSignup, error, isLoading, isPassHidden }
  }
}
</script>

<style>
  .signup .emojise-logo {
    margin: 15px auto;
  }
  .signup input {
    margin: 12px auto;
  }
  .signup button {
    margin: 20px auto 0 auto;
  }
  .signup .toggle-password {
    float: right;
    margin-top: -45px;
    cursor: pointer;
  }
</style>