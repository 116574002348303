<template>
  <h2 class="top-header">Welcome back 👋</h2>
  <span>Log in to your account</span>
  <form @submit.prevent="handleLogin">
    <input type="email" placeholder="Email Address" v-model="data.email">
    <input type="password" placeholder="Password" v-model="data.password">
    <div align="right"><span class="link" @click="handlePassword">Forgotten Password?</span></div>
    <div v-if="error" class="error">{{ error }}</div>
    <button v-if="!isLoading">Log In</button>
    <button v-else disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
  </form>
</template>

<script>
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Swal, Toast } from '@/composables/getGlobalVariables'
import useAuth from '@/composables/useAuth'

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { login, resetPass, error, isLoading } = useAuth()
    const data = reactive({
      email: '',
      password: ''
    })

    const handleLogin = async () => {
      await login(data)
      
      if (!error.value) {
        if (route.query.ref === 'saasmantra') {
          router.push({ name: 'Dashboard', params: { isNewLTD: true } })
        } else {
          router.push({ name: 'Dashboard' })
        }
      }
    }

    const handlePassword = async () => {
      const popup = await Swal.fire({
        title: 'Reset Password 🔒',
        text: 'Forgotten password? Happens to the best of us... Just enter your email address and we\'ll send you a reset link. ❤️',
        input: 'email',
        inputPlaceholder: 'Enter your email address',
        showCancelButton: true,
        confirmButtonText: 'Reset Password'
      })

      if (popup.isConfirmed) {
        await resetPass(popup.value)

        if (!error.value) {
          Toast.fire({
            title: 'Success!',
            text: 'Your password reset link was sent successfully',
            icon: 'success'
          })
        }
      }
    }

    return { data, handleLogin, handlePassword, error, isLoading }
  }
}
</script>

<style>
  .login .emojise-logo {
    margin: 20px auto;
  }
  .login button {
    margin: 35px auto 0 auto;
  }
</style>